import React, {useEffect, useState} from 'react';
import {Modal, Button, Form, Alert} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {loginSuccess, loginFailure, clearError} from '../store/authSlice';
import Config from "../Config";
import {setReload} from "../store/folderSlice";

function LoginModal({show, handleClose}) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();
    const error = useSelector(state => state.auth.error);  // Store'dan hata mesajını alıyoruz

    useEffect(() => {
        if (show) {
            dispatch(clearError());  // Modal açıldığında önceki hatayı temizliyoruz
        }
    }, [show, dispatch]);

    useEffect(() => {
        if(error) {
            console.log("error", error);
        }
    }, [error])

    const handleLogin = async () => {
        try {
            const response = await fetch(Config.api_base + '/users/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({user: {email, password}}),
            });

            if (response.ok) {
                const data = await response.json();
                dispatch(loginSuccess(data.user));
                handleClose();
                dispatch(setReload(true));
                setTimeout(() => window.location.reload(), 500)
            } else {
                const errorData = await response.json();
                if (response.status !== 200 && errorData.errors) {
                    dispatch(loginFailure(errorData.errors));  // Hata mesajını store'a kaydediyoruz
                } else {
                    dispatch(loginFailure('Login failed. Please try again.'));
                }
            }
        } catch (error) {
            dispatch(loginFailure('Login failed. Please try again.'));
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Login</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error && <>
                {
                    error && Object.keys(error).map(e => {
                    return <Alert variant="danger">{e} {error[e]}</Alert>
                    })

                }
                </>}
                <Form>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Enter email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleLogin}>
                    Login
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default LoginModal;