import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    currentFolder: null,
    defaultFolder: null,
    folders: null,
    files: null,
    breadcrumb: [],
    reload: true,
    showImage: false,
    selectedImage: null
};

const folderSlice = createSlice({
    name: 'filemanager',
    initialState,
    reducers: {
        setSelectedImage: (state, action) => {
            state.selectedImage = action.payload;
        },
        setShowImage: (state, action) => {
            state.showImage = action.payload;
        },
        setReload(state, action) {
            state.reload = action.payload;
        },
        setFolders(state, action) {
            state.folders = action.payload.folders;
        },
        setDefaultFolder(state, action) {
            state.defaultFolder = action.payload;
        },
        setCurrentFolder(state, action) {
            if (action.payload && action.payload.folder) {
                state.currentFolder = action.payload.folder;
            } else if (typeof action.payload === "object") {
                state.currentFolder = action.payload;
            } else {
                console.log("err action.payload", "type hatası");
                console.log("err action.payload", action.payload)
            }
        },
        setFiles(state, action) {
            state.files = action.payload.files;
        },
        addBreadcrumb(state, action) {
            try {
                if (action.payload) {
                    let temp_bc = [...state.breadcrumb];

                    // Eğer payload nesne veya dizi ise bu şekilde karşılaştırma yapılır
                    const exists = temp_bc.some(item => JSON.stringify(item) === JSON.stringify(action.payload));

                    if (!exists) {
                        temp_bc.push(action.payload);
                    }

                    state.breadcrumb = temp_bc;
                }
            } catch (e) {
                console.log(e);
            }
        },
        removeBreadcrumb(state) {
            state.breadcrumb.pop();  // Son elemanı sil
            console.log("rem breadcrumb", state.breadcrumb);
        },

        destroyBreadcrumb(state) {
            state.breadcrumb = [];
        }
    },
});

export const {
    setFiles,
    setCurrentFolder,
    setFolders,
    setDefaultFolder,
    addBreadcrumb,
    removeBreadcrumb,
    destroyBreadcrumb,
    setReload,
    setShowImage,
    setSelectedImage,
} = folderSlice.actions;
export default folderSlice.reducer;
