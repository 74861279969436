import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { initializeSocket, closeSocket } from '../../store/socketSlice';
import {toast} from "react-toastify";
import {setReload} from "../../store/folderSlice";

function Socket({show, handleClose}) {
    const dispatch = useDispatch();
    const socket = useSelector(state => state.socket.socket);
    const user = useSelector(state => state.auth.user);
    //const files = useSelector(state => state.filemanager?.files);

    useEffect(() => {
        if (user && user.token) {
            dispatch(initializeSocket(user.token));
        }

        return () => {
            dispatch(closeSocket());
        };
    }, [dispatch, user]);


    useEffect(() => {
        if (socket) {
            socket.on("image.removed", (data) => {
                toast(`${data.name} deleted`, {type: "info"});
                //_.remove(files, (file) => file.slug === data.slug);
                //dispatch(setFiles(files));
                dispatch(setReload(true));
            });

            socket.on("reload", (data) => {
                dispatch(setReload(true));
                toast("Reloaded", {type: "info"});
            });

            socket.on("joined", (message, data) => {
                toast("Secure connection established", {type: "info"});
            });

            return () => {
                socket.off("message");
            };
        }
    }, [socket, dispatch]);

    return (
        <></>
    );
}

export default Socket;