import { createSlice } from '@reduxjs/toolkit';
import { io } from 'socket.io-client';
import Config from "../Config";

const socketSlice = createSlice({
    name: 'socket',
    initialState: {
        socket: null,
    },
    reducers: {
        initializeSocket(state, action) {
            const userToken = action.payload;
            if (!state.socket) {
                state.socket = io(Config.ws_base, {
                    reconnectionDelayMax: 10000,
                    upgrades: ["websocket"],
                    auth: {
                        token: userToken,
                    },
                    query: {
                        "token": userToken,
                    },
                });
            }
        },
        closeSocket(state) {
            if (state.socket) {
                state.socket.close();
                state.socket = null;
            }
        }
    }
});

export const { initializeSocket, closeSocket } = socketSlice.actions;
export default socketSlice.reducer;
