import React from 'react';
import {Modal, } from 'react-bootstrap';
import ImageUpload from "../Home/ImageUploader";

function UploadModal({show, handleClose}) {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>File Upload</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ImageUpload/>

            </Modal.Body>
            {/*<Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Save
                </Button>
            </Modal.Footer>*/}
        </Modal>
    );
}

export default UploadModal;
