import React, {useState} from 'react';
import {Card, Col, Row, Stack} from "react-bootstrap";
import Loading from "../_layout/Loading";
import "../_layout/Loading.css";
import Config from "../../Config"
import {toast} from 'react-toastify';
import UrlList from "./UrlList";
import {useDispatch, useSelector} from "react-redux";
import {setReload} from "../../store/folderSlice";
import {BiFolderOpen} from "react-icons/bi";

const ImageUpload = () => {
    const [file, setFile] = useState(null);
    //const [, setParent] = useState('');
    const [timeoutValue, setTimeoutValue] = useState(0);
    const [noteValue, setNoteValue] = useState("");
    const [uploadedImageUrl, setUploadedImageUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [id, setId] = useState();
    const [, setUrls] = useState({
        view: "",
        direct: "",
        bbcode: "",
        html: ""
    });
    //const folders = useSelector(state => state.filemanager?.folders);  // Store'dan hata mesajını alıyoruz
    const currentFolder = useSelector(state => state.filemanager?.currentFolder);  // Store'dan hata mesajını alıyoruz
    const defaultFolder = useSelector(state => state.filemanager?.defaultFolder);
    const breadcrumb = useSelector(state => state.filemanager?.breadcrumb);
    const user = useSelector(state => state.auth.user);
    const dispatch = useDispatch();

    /*const handleFolderSelect = (folder) => {
        setParent(folder._id);
        dispatch(setCurrentFolder(folder));
    }*/
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
    };

    const handleTimeoutChange = (event) => {
        const value = event.target.value;
        setTimeoutValue(value);
    };
    const handleNoteChange = (event) => {
        const value = event.target.value;
        // Girilen metni 255 karaktere sınırla

        if (value.length <= 255) {
            setNoteValue(value);
        } else {
            // 255 karakterden fazla ise son 255 karakteri al
            setNoteValue(value.slice(0, 255));
        }
    };

    const handleSubmit = async (event) => {
        if (!file) {
            toast.error("Select file");
            return;
        }
        event.preventDefault();
        setLoading(true);
        const formData = new FormData();
        formData.append('image', file);

        try {
            if (currentFolder === undefined || currentFolder === null) {
                toast("Please Select Folder", {type: "error"});
                setLoading(false);
                return;
            }
            console.log("defaultFolder", defaultFolder);
            const response = await fetch(`${Config.api_base}/v1/widget/image/upload?timeout=${timeoutValue}&folder=${currentFolder._id}&description=${noteValue}`, {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${user.token}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                /*console.log("data", data[0]);*/
                const image_info = data[0];
                setId(image_info.slug);
                const urls = {
                    view: `${Config.view_base}/${image_info.slug}`,
                    direct: `${Config.image_base}/image/direct/${image_info.slug}`,
                    bbcode: `[url=${Config.view_base}/${image_info.slug}][img]${Config.image_base}/image/direct/${image_info.slug}[/img][/url]`,
                    bbcode_thumb: `[url=${Config.view_base}/${image_info.slug}][img]${Config.image_base}/image/direct/${image_info.slug}/thumb[/img][/url]`,
                    html: `${Config.view_base}/${image_info.slug}`,
                    full_html: `<a href="${Config.view_base}"><img src="${Config.image_base}/image/direct/${image_info.slug}" alt="Random" border="0"></a>`,
                }
                setUrls(urls);
                setUploadedImageUrl(`${image_info.domain}/${image_info.path}/${image_info.file}`);
                setNoteValue("");
                dispatch(setReload(true));
                toast("Image Uploaded");
            } else {
                const data = await response.json();
                toast(`${data.message}`, {type: "error"});
                setLoading(false);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error:', error);
        }
    };


// your function to copy here

    return (
        <>
            {uploadedImageUrl && (
                <Row className={"mb-4"}>
                    <Col>
                        <Card className={"shadow"}>
                            <Card.Header className={"shadow-sm"}><h3>Uploaded Image</h3></Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col className={"text-center"}>
                                        <img src={uploadedImageUrl} alt="Uploaded"
                                             className={"img-fluid rounded rounded-2"}/>
                                    </Col>
                                    <Col>
                                        <UrlList id={id} image_url={uploadedImageUrl}/>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            )}

            <Row>
                <Col>
                    <Card className={"shadow"}>
                        <Card.Header><h3>Upload Image</h3></Card.Header>
                        <Card.Body>
                            {loading ? <Loading/> :
                                <Row>
                                    <Col>
                                        <label className={"w-100 mb-2"}>
                                            <small>Select an image:</small>
                                            <input type="file" className={"form-control"} accept="image/*"
                                                   onChange={handleFileChange}/>
                                        </label>
                                        <Stack gap={2} direction={"horizontal"} className={"mb-2"}>
                                            <label className={"w-100 mb-2"}>
                                                <small>Select Folder:</small> <br/>
                                                <nav aria-label="breadcrumb">
                                                    <ol className="breadcrumb mb-0">
                                                        <li className="breadcrumb-item">
                                                            <BiFolderOpen/> Root
                                                        </li>
                                                        {breadcrumb.map((crumb, i) => (
                                                            <li className={`breadcrumb-item ${(i + 1) >= breadcrumb.length ? "active" : ""}`}
                                                                key={`${crumb._id}-${Math.floor(Math.random() * 1010)}`}>
                                                                <BiFolderOpen/> {crumb.name}</li>
                                                        ))}
                                                    </ol>
                                                </nav>
                                                {/*<div className="btn-group">
                                                    <button className="btn  dropdown-toggle"
                                                            type="button" data-bs-toggle="dropdown"
                                                            aria-expanded="false">
                                                        <img alt={"default folder"}
                                                             src={"/assets/images/icons/Pictures.svg"}
                                                             className={"img-folder-m me-2"}/> {currentFolder?.name ?? defaultFolder?.name}
                                                    </button>
                                                    <ul className="dropdown-menu">
                                                        <li>
                                                            <button className="dropdown-item" type={"button"}
                                                                    onClick={() => handleFolderSelect(null)}>
                                                                <img alt={"default folder"}
                                                                     src={"/assets/images/icons/Pictures.svg"}
                                                                     className={"img-folder-m me-2"}/> -
                                                            </button>
                                                        </li>
                                                        {folders && folders.map((folder) => (
                                                            <li key={folder._id}>
                                                                <button className="dropdown-item" type={"button"}
                                                                        onClick={() => handleFolderSelect(folder)}>
                                                                    <img alt={"default folder"}
                                                                         src={"/assets/images/icons/Pictures.svg"}
                                                                         className={"img-folder-m me-2"}/>
                                                                    {folder.name}</button>
                                                            </li>
                                                        ))}

                                                    </ul>

                                                </div>*/}
                                            </label>
                                        </Stack>
                                        <Stack gap={2} direction={"horizontal"} className={"mb-2"}>
                                            <label className={"w-100"}>
                                                <small>Description:</small>
                                                <textarea className={"form-control"} value={noteValue} maxLength={500}
                                                          onChange={handleNoteChange}
                                                />
                                            </label>
                                        </Stack>
                                        <Stack gap={2} direction={"horizontal"} className={"mb-2"}>
                                            <label>
                                                <small>Delete at:</small>
                                                <select className={"form-select"}
                                                        disabled={false}
                                                        onChange={handleTimeoutChange}
                                                        defaultValue={timeoutValue}
                                                >
                                                    <option value={0}>Never Delete</option>
                                                    <option value={1}>Delete after 1 Min</option>
                                                    <option value={5}>Delete after 5 Min</option>
                                                    <option value={10}>Delete after 10 Min</option>
                                                    <option value={15}>Delete after 15 Min</option>
                                                    <option value={30}>Delete after 30 Min</option>
                                                    <option value={60}>Delete after 1 Hour</option>
                                                    <option value={120}>Delete after 2 Hour</option>
                                                    <option value={300}>Delete after 5 Hour</option>
                                                </select>
                                            </label>
                                            <button type="button" onClick={handleSubmit}
                                                    className={"btn btn-success ms-auto mt-4"}>Submit
                                            </button>
                                        </Stack>
                                    </Col>
                                </Row>
                            }


                        </Card.Body>
                        <Card.Footer>
                            <small className={"text-muted"}>* This is beta test.</small>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default ImageUpload;
