import React from "react"
import {Card, Col, Container, Row} from "react-bootstrap";
import ImageUpload from "./ImageUploader";
import Folders from "../Folders";
import {useSelector} from "react-redux";
import ImageShow from "./ImageShow";

function Home() {
    //const is_folder_selected = useSelector(state => state.style.is_folder_selected);
    const showImage = useSelector(state => state.filemanager?.showImage);
    const selectedImage = useSelector(state => state.filemanager.selectedImage);
    const user = useSelector(state => state.auth?.user);
    if (user) {

    }
    return (
        <Container style={{marginBottom: "150px"}}>
            <Row>
                <Col className={"text-center"}>
                    <h1>Upload and share your images.</h1>
                    <p>Start uploading your images now. 32 MB limit. Direct image links, BBCode and HTML thumbnails.</p>
                </Col>
            </Row>
            <Row>
                <Col md={4} className={""}>
                    {!showImage ? <ImageUpload/> : <ImageShow image={selectedImage}/>}
                </Col>
                <Col md={8} className={"d-none d-md-block"}>
                    <Folders/>
                </Col>
            </Row>

            <Row className={"mt-5"}>
                <Col>
                    <h2 className={"text-center"}>Features</h2>
                </Col>
            </Row>

            <Row className={"mt-2 g-3"}>
                <Col>
                    <Card>
                        <Card.Body style={{minHeight: "250px"}}>Select images by clicking on the box. You can upload
                            many images with a single upload. You can share all images as a single file with your
                            friends.</Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Card.Body style={{minHeight: "250px"}}>Protect your uploaded images and give access to certain
                            people only, full privacy for your images. All Images are secured with a private
                            tunnel.</Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Card.Body style={{minHeight: "250px"}}>Adjust the timeframe for uploaded images from 1 hour to
                            an indefinite duration. You have the flexibility to determine when they should expire, and
                            there's no requirement for creating an account to upload images with unlimited
                            duration.</Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row className={"mt-4"}>
                <Col className={"text-center"}>
                    <ins className="656388b674ca5380b8cbba07"
                         style={{display: "inline-block", width: "1px", height: "1px"}}></ins>
                </Col>
            </Row>
        </Container>
    )
}

export default Home
