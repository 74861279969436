import React, {useState} from "react";
import Pricing from "./index";

function UpgradeButton() {
    const [modal01, setModal01] = useState(false);

    const handleClose = () => {
        setModal01(false);
    }

    return (
        <>
            <button className="btn btn-primary" onClick={() => setModal01(!modal01)}>
                Upgrade
            </button>
            <Pricing handleClose={handleClose} show={modal01}/>
        </>
    )
}

export default UpgradeButton;
