import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    is_folder_selected: false,
    error: null,
};

const styleSlice = createSlice({
    name: 'style',
    initialState,
    reducers: {
        folderSelected(state, action) {
            state.is_folder_selected = action.payload;
        },
        clearError(state) {
            state.error = null;
        }
    },
});

export const {folderSelected, clearError} = styleSlice.actions;
export default styleSlice.reducer;
