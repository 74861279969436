import React, {useEffect, useState} from "react";
import {Card} from "react-bootstrap";
import Countdown from "../View/Countdown";

const Comment = ({data}) => {
    const [type, setType] = useState("uploader"); // or guest
    const [note, setNote] = useState(""); // or guest
    const [guestNum, setGuestNum] = useState(0); // or guest
    const [boxStyles, setBoxStyles] = useState("");
    const [username, setUsername] = useState("");
    const [createdAt, setCreatedAt] = useState();
    const [ttl, setTtl] = useState(3600);

    useEffect(() => {
        setType(data.type);
        setNote(data.note ?? data.comment);
        setGuestNum(data.guestNum ?? data.guest_num);
        setTtl(data.ttl);
//console.log("data",data);
        setCreatedAt(data.createdAt);

        if (type === "uploader") {
            setBoxStyles("mb-2 bg-primary-subtle border-primary-subtle");
            setUsername("Uploader")
        } else {
            setBoxStyles("mb-2 bg-secondary-subtle border-secondary-subtle shadow ms-4 mt-4");
            setUsername(`Guest${guestNum}`);
        }

    }, [data, guestNum, type]);

    return <Card className={boxStyles}>
        <Card.Body className={"p-1 ps-2"}>
            <p><span className={"fw-bold"}>{username}:</span> {note}</p>
        </Card.Body>
        <Card.Footer style={{fontSize: "0.8rem"}} className={"text-end"}>
            {type === "uploader" ? <><span className={"fw-bold small"}>Time to delete: </span><Countdown ttl={ttl} /></> :
                <small>{(new Date(createdAt)).toLocaleString()}</small>}
        </Card.Footer>
    </Card>;
}

export default Comment;