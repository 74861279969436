import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {Button, InputGroup, Stack} from "react-bootstrap";
import Config from "../../Config";
const UrlList = ({id, image_url, img_link= true}) => {
    const [urls, setUrls] = useState();
    useEffect(() => {
        setUrls({
            view: `${Config.view_base}/${id}`,
            direct: `${image_url}`,
            bbcode: `[url=${Config.view_base}/${id}][img]${image_url}[/img][/url]`,
            bbcode_thumb: `[url=${Config.view_base}/${id}][img]${image_url}?class=thumbnail[/img][/url]`,
            html: `${Config.view_base}/${id}`,
            full_html: `<a href="${Config.view_base}"><img src="${image_url}" alt="Random" border="0"></a>`,
        });

    }, [id, image_url]);
    const handleFocus = (event) => event.target.select();

    const copyToClipBoard = async copyMe => {
        try {
            await navigator.clipboard.writeText(copyMe);
            toast("Copied !");
            //setCopySuccess('Copied!');
        } catch (err) {
            toast.error("Cannot copied");
            //setCopySuccess('Copy');
        }
    };
    return urls && <Stack gap={2} direction={"vertical"} className={"align-items-start"}>
        <label className={"w-100"}>
            <small>View Url</small>
            <InputGroup className="mb-3">
                <Button variant="outline-success"
                        onClick={() => copyToClipBoard(urls.view)}>
                    Copy
                </Button>
                <input type="text" readOnly={true} value={urls.view}
                       onFocus={handleFocus}
                       onClick={() => copyToClipBoard(urls.view)}
                       className={"form-control form-control-sm"}/>
            </InputGroup>
        </label>
        {/*<label className={"w-100"}>
            <small>Direct Link</small>
            <InputGroup className="mb-3">
                <Button variant="outline-success"
                        onClick={() => copyToClipBoard(urls.direct)}>
                    Copy
                </Button>
                <input type="text" readOnly={true} value={urls.direct}
                       onFocus={handleFocus}
                       onClick={() => copyToClipBoard(urls.view)}
                       className={"form-control form-control-sm"}/>
            </InputGroup>
        </label>*/}
        <label className={"w-100"}>
            <small>BBCode</small>
            <InputGroup className="mb-3">
                <Button variant="outline-success"
                        onClick={() => copyToClipBoard(urls.bbcode)}>
                    Copy
                </Button>
                <input type="text" readOnly={true} value={urls.bbcode}
                       onFocus={handleFocus}
                       onClick={() => copyToClipBoard(urls.bbcode)}
                       className={"form-control form-control-sm"}/>
            </InputGroup>
        </label>
        <label className={"w-100"}>
            <small>BBCode Thumb</small>
            <InputGroup className="mb-3">
                <Button variant="outline-success"
                        onClick={() => copyToClipBoard(urls.bbcode_thumb)}>
                    Copy
                </Button>
                <input type="text" readOnly={true} value={urls.bbcode_thumb}
                       onFocus={handleFocus}
                       onClick={() => copyToClipBoard(urls.bbcode_thumb)}
                       className={"form-control form-control-sm"}/>
            </InputGroup>
        </label>
        <label className={"w-100"}>
            <small>Full Html</small>
            <InputGroup className="mb-3">
                <Button variant="outline-success"
                        onClick={() => copyToClipBoard(urls.full_html)}>
                    Copy
                </Button>
                <input type="text" readOnly={true} value={urls.full_html}
                       onFocus={handleFocus}
                       onClick={() => copyToClipBoard(urls.full_html)}
                       className={"form-control form-control-sm"}/>
            </InputGroup>

        </label>

        {img_link && <a href={urls.view}>View Image</a>}
    </Stack>
};

export default UrlList;
