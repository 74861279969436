import React, {useEffect} from 'react';
import {Modal, Button} from 'react-bootstrap';
import {useSelector} from "react-redux";

function Pricing({show, handleClose}) {
    const user = useSelector(state => state.auth.user);

    useEffect(() => {
        const loadExternalScript = () => {
            const scriptElement = document.createElement('script');
            scriptElement.type = 'text/javascript';
            scriptElement.async = true;
            scriptElement.src = 'https://js.stripe.com/v3/pricing-table.js';

            scriptElement.onerror = () => {
                scriptElement.remove();
            };

            document.body.appendChild(scriptElement);
        };


        loadExternalScript();

        return () => {
            // Cleanup if needed (optional)
            const existingScript = document.querySelector('script[src="https://js.stripe.com/v3/pricing-table.js"]');
            if (existingScript) {
                existingScript.remove();
            }
        };
    }, []);

    return (
        <Modal show={show} onHide={handleClose} size={"xl"}>
            <Modal.Header closeButton>
                <Modal.Title>Upgrade Your Plan</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <stripe-pricing-table pricing-table-id="prctbl_1PuvzUJYipnlMBkgz04cbHGk"
                                      publishable-key="pk_live_51Pkw5PJYipnlMBkgbZAWxHKDmHJL89PTFI5CnSlSeY4u90kQctI2SpY9koI9AeAkk72UKd6IswiPP42lY7Ugygyb008qZJMlLS">
                                      customer-email={user.email}
                </stripe-pricing-table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default Pricing;
