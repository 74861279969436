import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    user: null,
    error: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginSuccess(state, action) {
            state.user = action.payload;
            state.error = null;
        },
        loginFailure(state, action) {
            state.user = null;
            console.log("action.payload",action);
            state.error = action.payload;
        },
        clearError(state) {
            state.error = null;
        }
    },
});

export const { loginSuccess, loginFailure, clearError } = authSlice.actions;
export default authSlice.reducer;
