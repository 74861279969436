import React, { useEffect, useState } from "react";

const Countdown = ({ ttl }) => {
    const [time, setTime] = useState({
        hours: 0,
        minutes: 0,
        seconds: 0
    });

    useEffect(() => {
        // İlk başlangıçta zamanı ayarla
        setTime(secondsToTime(ttl));

        // Her saniyede bir sürekli güncellemeleri yap
        const interval = setInterval(() => {
            setTime((prevTime) => {
                const totalSeconds = timeToSeconds(prevTime);
                if (totalSeconds > 0) {
                    const newTime = secondsToTime(totalSeconds - 1);
                    return newTime;
                } else {
                    // Zaman doldu, süreci sonlandır
                    clearInterval(interval);
                    return prevTime;
                }
            });
        }, 1000);

        return () => {
            // Bileşen ayrıldığında interval'i temizle
            clearInterval(interval);
        };
    }, [ttl]);

    const timeToSeconds = (time) => {
        return time.hours * 3600 + time.minutes * 60 + time.seconds;
    };
    const formattedSeconds = (seconds) => (seconds < 10) ? `0${seconds}` : seconds;

    const secondsToTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        return {
            hours,
            minutes,
            seconds: remainingSeconds
        };
    };

    return (
        <span>
            {time.hours > 0 && <span>{formattedSeconds(time.hours)}:</span>}
            <span>{formattedSeconds(time.minutes)}:{formattedSeconds(time.seconds)}</span>
        </span>
    );
};

export default Countdown;
