import React, {useState} from 'react';
import {Modal, Button, Form, Alert, Stack} from 'react-bootstrap';
import Config from '../../Config';
import {useDispatch, useSelector} from "react-redux";
import {setCurrentFolder, setFolders, setReload} from "../../store/folderSlice";

function CreateFolderModal({show, handleClose}) {
    const [name, setName] = useState('');
    const [parent, setParent] = useState('');
    const [error, setError] = useState(null);
    const [, setSuccess] = useState(false);
    const folders = useSelector(state => state.filemanager.folders);
    const currentFolder = useSelector(state => state.filemanager.currentFolder);
    const user = useSelector(state => state.auth.user);
    const dispatch = useDispatch();

    const handleSave = async () => {
        setError(null);
        setSuccess(false);

        const body = {
            name: name,
            parent: parent || currentFolder._id || ""  // Eğer parent boşsa, boş string gönder
        };

        try {
            const response = await fetch(Config.api_base + '/v1/filemanager', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`,
                },
                body: JSON.stringify(body),
            });

            if (!response.ok) {
                const errorData = await response.json();
                setError(errorData.errors || {message: 'Failed to save data.'});
            } else {
                setSuccess(true);
                const new_folder = await response.json();
                const temp_folders = [...folders, new_folder]
                dispatch(setFolders({folders: temp_folders}));
                handleClose();
            }
        } catch (error) {
            setError({message: 'An error occurred. Please try again.'});
        }
    };
    const handleFolderSelect = (folder) => {
        setParent(folder._id);
        dispatch(setCurrentFolder(folder));
        dispatch(setReload(true));
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Create New Folder/File</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error && <>
                    {
                        Object.keys(error).map(e => (
                            <Alert variant="danger" key={e}>{e} {error[e]}</Alert>
                        ))
                    }
                </>}
                <Form>
                    <Form.Group controlId="formName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="formParent">
                        <Stack gap={2} direction={"vertical"} className={"mb-2"}>
                            <Form.Label>Select Folder</Form.Label>
                            <div className="btn-group w-25">
                                <button className="btn  dropdown-toggle"
                                        type="button" data-bs-toggle="dropdown"
                                        aria-expanded="false">
                                    {currentFolder ? <>
                                        <img alt={"default folder"} src={"/assets/images/icons/Pictures.svg"}
                                             className={"img-folder-m me-2"}/> {currentFolder.name}
                                    </> : <><img alt={"default folder"} src={"/assets/images/icons/Pictures.svg"}
                                                 className={"img-folder-m me-2"}/> -</>}

                                </button>
                                <ul className="dropdown-menu">
                                    <li>

                                        <button className="dropdown-item" type={"button"}
                                           onClick={() => handleFolderSelect(null)}>
                                            <img alt={"default folder"} src={"/assets/images/icons/Pictures.svg"}
                                                 className={"img-folder-m me-2"}/> -</button>
                                    </li>
                                    {folders && folders.map((folder) => (
                                        <li key={folder._id}>
                                            <button className="dropdown-item" type={"button"}
                                               onClick={() => handleFolderSelect(folder)}>
                                                <img alt={"default folder"} src={"/assets/images/icons/Pictures.svg"}
                                                     className={"img-folder-m me-2"}/>
                                                {folder.name}</button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </Stack>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default CreateFolderModal;
