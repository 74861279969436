//https://img-api.rpd.ink
//https://i.rpd.ink

const Config = {
    view_base: "https://img.rpd.ink",
    api_base: "https://img-api.rpd.ink/api",
    image_base: "https://img-cdn.rpd.ink",
    ws_base: "https://img-ws.rpd.ink/",
}
export default Config;
