import React from 'react';

const Loading = () => {
    return    <div className="spinner">
        <span>Loading...</span>
        <div className="half-spinner"></div>
    </div>;
}

export default Loading;
