import Comment from "./index";
import {Button, Card, InputGroup} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import axios from "axios";
import Config from "../../Config";

const Comments = ({data}) => {
    const [id, setId] = useState();
    const [comments, setComments] = useState();
    const [ttl, setTtl] = useState(3600);
    const [myCommentState, setMyCommentState] = useState("");

    useEffect(() => {
        setId(data.id);
        setComments(data.comments);
        setTtl(data.ttl);
        //console.log("effect",data);
    }, [data]);

    const saveComment = async (data) => {
        try {
            //setLoading(true);
            const response = await axios.post(Config.api_base + "/api/image/comment/save", data);

            if (response.data.status === 404) {
                console.log(404)
            } else {
                //console.log("saved",response.data);
                return response.data;
            }


        } catch (error) {
            //toast.error("Image removed");
            console.error('Hata:', error);
        }
    }

    const commentSubmitHandler = () => {
        const guestNumStorage = Number(localStorage.getItem("guestNum"))
        const newCommentData = {
            id: id,
            guest_num: guestNumStorage,
            comment: myCommentState,
            createdAt: new Date()
        }
        if(comments) {
            setComments([...comments, newCommentData]);
        } else {
            setComments([newCommentData]);
        }
        setMyCommentState("");
        saveComment(newCommentData).then(res => console.log("Comment saved"));

    }

    const commentHandler = (e) => {
        setMyCommentState(e.target.value);
    }

    return <div className={"col overflow-auto"} style={{height: "100vh"}}>
        {comments && Object.values(comments).reverse().map((comment, index) => {
            comment.ttl = ttl;
            return <Comment data={comment} key={"a" + index}/>
        })}

        <Card className={"bg-warning-subtle border-warning-subtle shadow position-fixed bottom-0"}
              style={{width: "33%", marginBottom: "100px"}}>
            <Card.Body className={"p-2"}>
                <InputGroup>
                    <textarea className={"form-control bg-warning-subtle border-success"} style={{fontSize: "0.9rem"}}
                              placeholder={"Write your comments"} value={myCommentState}
                              onChange={commentHandler}/>
                    <Button variant="outline-success" onClick={commentSubmitHandler}>Comment</Button>
                </InputGroup>
            </Card.Body>
        </Card>
    </div>


};

export default Comments;
