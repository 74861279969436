import React, {useCallback, useEffect, useState} from 'react';
import "../_layout/Loading.css";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import Config from "../../Config";
import {Card, Stack} from "react-bootstrap";
import {
    BiColumns,
    BiFolderPlus,
    BiFolderOpen,
    BiUpload,
    BiArrowBack,
    BiDownArrow,
    BiUpArrow,
    BiSolidTrash, BiPencil, BiReply, BiRepeat
} from "react-icons/bi";
import Loading_svg from "./Loading.svg"

import {
    setFolders,
    setCurrentFolder,
    setFiles,
    setDefaultFolder,
    addBreadcrumb,
    destroyBreadcrumb, removeBreadcrumb, setReload, setShowImage, setSelectedImage
} from '../../store/folderSlice';
import {folderSelected} from "../../store/styleSlice";
import CreateFolderModal from './CreateFolderModal';
import _ from "lodash";
import UploadModal from "../Files/UploadModal";
import {toast} from "react-toastify";
import {loginSuccess} from "../../store/authSlice";

const Folders = () => {
    const user = useSelector(state => state.auth.user);
    const [folderModal01, setFolderModal01] = useState(false);
    const [folderModal02, setFolderModal02] = useState(false);
    const [expandFileList, setExpandFileList] = useState(false);
    const [maxFileHeight, setMaxFileHeight] = useState("500px");
    const [loadingImages, setLoadingImages] = useState({});

    let api_endpoint = `/v1/filemanager/folder/`;
    const dispatch = useDispatch();

    const currentFolder = useSelector(state => state.filemanager?.currentFolder);
    const folders = useSelector(state => state.filemanager?.folders);
    const breadcrumb = useSelector(state => state.filemanager?.breadcrumb);
    const files = useSelector(state => state.filemanager?.files);
    const is_folder_selected = useSelector(state => state.style.is_folder_selected);

    // const files = useSelector(state => state.filemanager?.files);
    // const defaultFolder = useSelector(state => state.filemanager?.defaultFolder);
    const reload = useSelector(state => state.filemanager.reload);

    const getFolders = useCallback(async (folder_id) => {
        try {
            if (!folder_id) {
                folder_id = "";
                dispatch(destroyBreadcrumb());
            }
            const response = await fetch(Config.api_base + api_endpoint + folder_id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`,
                }
            });

            if (response.ok) {
                const new_folder_list = await response.json();
                dispatch(setFolders(new_folder_list));
                dispatch(setFiles(new_folder_list));

                const default_folder = _.find(new_folder_list.folders, { name: "default" });
                dispatch(setDefaultFolder(default_folder));

                if (folder_id) {
                    dispatch(folderSelected(true));
                }
                dispatch(setCurrentFolder(new_folder_list));

                return new_folder_list;
            } else {
                const errorData = await response.json();
                if (response.status !== 200 && errorData.errors) {
                    console.log("errorData", errorData);
                } else {
                    console.log("errorData", errorData);
                }
            }
        } catch (error) {
            console.log("errorData", error);
        }
    }, [dispatch, user.token,api_endpoint]);

    const handleClose = () => {
        //dispatch(setCurrentFolder(null));
        setFolderModal01(false);
    }

    const handleUploadModalClose = () => {
        setFolderModal02(false);
    }

    const handlerFolderSelect = useCallback((folder) => {
        let folder_id = null;
        if (folder) {
            folder_id = folder._id;
            dispatch(addBreadcrumb(folder));
        }
        getFolders(folder_id).then(folders => {
            // ..
        });
    }, [dispatch,getFolders]);

    const handlerBack = async () => {
        await dispatch(removeBreadcrumb());

        if (breadcrumb.length > 1) {
            let folder_index;
            if (breadcrumb.length - 2 <= 0) {
                folder_index = 0;
            } else {
                folder_index = breadcrumb.length - 2
            }

            const prev_folder = breadcrumb[folder_index];
            /*console.log("prev_folder", prev_folder);*/
            handlerFolderSelect(prev_folder);
        } else {
            /*console.log("sadece handler")*/
            handlerFolderSelect();
        }
    }

    const handlerFileSelect = (file) => {
        dispatch(setShowImage(true));
        dispatch(setSelectedImage(file))
    }

    const handlerImageError = (e, fileId) => {
        const old_url = e.target.src;
        e.target.src = Loading_svg;
        setLoadingImages(prevState => ({...prevState, [fileId]: true}));

        setTimeout(() => {
            e.target.src = old_url;
            setLoadingImages(prevState => ({...prevState, [fileId]: false}));

        }, 5000)
    }

    const handlerFileDelete = async (file, e) => {
        const file_id = file._id;
        const response = await fetch(`${Config.api_base}/v1/widget/${file_id}`, {
            method: 'DELETE',
            body: JSON.stringify({
                widget: file.type,
                id: file._id,
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`,
            },
        });

        if (response.ok) {
            //const new_files = files.filter(file => file._id !== file_id);
            dispatch(setReload(true));
        } else {
            toast("Error on file delete", {type: "error"});
        }
    }

    const handlerFolderDelete = async (file) => {
        const file_id = file._id;
        const response = await fetch(`${Config.api_base}/v1/filemanager/${file_id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${user.token}`,
            },
        });

        if (response.ok) {
            toast("Folder Deleted");
            //const new_files = files.filter(file => file._id !== file_id);
            dispatch(setReload(true));
        } else {
            toast("Error on file delete", {type: "error"});
        }
    }

    useEffect(() => {
        if (!folders) {
            getFolders().catch(error => {
                console.log("error.message", error.message);
            })
        }

    }, [folders, dispatch, getFolders]);

    useEffect(() => {
        if (reload && currentFolder) {
            handlerFolderSelect(currentFolder);
            dispatch(setReload(false))

             fetch(`${Config.api_base}/user`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${user.token}`,
                },
            }).then(me_response => {
                 if(me_response.ok) {
                      me_response.json().then(data => {
                          dispatch(loginSuccess(data.user));
                      });
                 }
             });

            /*console.log(currentFolder);*/
        }
    }, [reload, currentFolder, dispatch, handlerFolderSelect,user.token]);

    useEffect(() => {
        if (expandFileList) {
            setMaxFileHeight("800px");
        } else {
            setMaxFileHeight("500px");
        }
    }, [expandFileList]);

    return (
        <Card>
            <Card.Header>
                <Stack gap={2} direction={"horizontal"}>
                    <div className={"p-2"}>
                        <button className={"btn-link border-0"}
                                onClick={() => dispatch(folderSelected(!is_folder_selected))} type={"button"}>
                            <BiColumns/></button>
                        Folders
                    </div>
                    <div className="p-2">
                        {breadcrumb.length > 0 &&
                            <button type={"button"} className={"link-primary border-0"} onClick={() => handlerBack()}>
                                <BiArrowBack/></button>}
                    </div>
                    <div className="vr"></div>
                    <nav aria-label="breadcrumb me-auto">
                        <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item">
                                <button type={"button"} className={"border-0"} onClick={() => handlerFolderSelect()}>
                                    <BiFolderOpen/> Root
                                </button>
                            </li>
                            {breadcrumb.map((crumb, i) => (
                                <li className={`breadcrumb-item ${(i + 1) >= breadcrumb.length ? "active" : ""}`}
                                    key={`${crumb._id}-${Math.floor(Math.random() * 1010)}`}>
                                    <BiFolderOpen/> {crumb.name}</li>
                            ))}
                        </ol>
                    </nav>
                    <div className={"ms-auto pr-1"}>
                        <button type={"button"} className={"btn-link border-0"} onClick={() => dispatch(setReload(true))}>
                            <BiRepeat />
                        </button>
                    </div>
                    <div className="vr"></div>
                    <div className="p-1">
                        <a href="#createFolder" onClick={() => setFolderModal01(true)}><BiFolderPlus/></a>
                        <CreateFolderModal show={folderModal01} handleClose={() => handleClose()}/>
                    </div>
                    <div className="p-1">
                        {(breadcrumb.length) > 0 &&
                            <a href="#createFolder" onClick={() => setFolderModal02(true)}><BiUpload/></a>}
                        <UploadModal handleClose={() => handleUploadModalClose()} show={folderModal02}/>
                    </div>
                </Stack>
            </Card.Header>
            <Card.Body className="p-0" style={{maxHeight: maxFileHeight, overflowY: "auto"}}>
                <div className="list-group">
                    {currentFolder?.name &&
                        <div className="list-group-item list-group-item-action" style={{cursor: "pointer"}}
                             aria-current="true" >
                            <div className="d-flex w-100 justify-content-between">
                                <h5 className="mb-1" onClick={() => handlerBack()}>
                                    <Stack direction={"horizontal"} gap={2}>
                                        <img alt={"default folder"} src={"/assets/images/icons/Pictures.svg"}
                                             className={"img-folder-m me-2"}/>
                                        <div
                                            className={`ps-2`}>
                                            <span className={"fw-bold"}>...</span> <small className={"small"}>({breadcrumb[breadcrumb.length-2]?.name ?? "Root"})</small>
                                            <BiReply className={"rotate-90"} />
                                        </div>
                                    </Stack>
                                </h5>

                            </div>
                        </div>}
                    {folders && folders.map((folder) => (
                        <div className="list-group-item list-group-item-action" style={{cursor: "pointer"}}
                             aria-current="true" key={folder._id}>
                            <div className="d-flex w-100 justify-content-between">
                                <h5 className="mb-1" onClick={() => handlerFolderSelect(folder)}>
                                    <Stack direction={"horizontal"} gap={2}>
                                        <img alt={"default folder"} src={"/assets/images/icons/Pictures.svg"}
                                             className={"img-folder-m me-2"}/>
                                        <div
                                            className={`ps-2 folder-name-overflow`}>{folder.name}</div>
                                        <div className={"vr ms-auto"}></div>
                                    </Stack>
                                </h5>
                                <Stack direction={`horizontal`} gap={2}>
                                    <div className="ps-2 mr-2">
                                        <small
                                            className={"folder-time-align-end"}>{moment(folder.updatedAt).fromNow()}</small>

                                    </div>
                                    <Stack direction={`vertical`} gap={0}
                                           className="ps-2 mr-2">
                                        <button type={"button"} className={"link-primary border-0 text-warning"}>
                                            <BiPencil/>
                                        </button>
                                        {folder.name !== "default" &&
                                            <button type={"button"} className={"link-primary border-0 text-danger"}
                                                    onClick={() => handlerFolderDelete(folder)}>
                                                <BiSolidTrash/>
                                            </button>}
                                    </Stack>
                                </Stack>
                            </div>
                        </div>
                    ))}

                    {files && files.map((file) => (
                        <div className="list-group-item list-group-item-action" aria-current="true" key={file._id}>
                            <div className="d-flex w-100 justify-content-between">
                                <h5 className="mb-1">
                                    <Stack direction={"horizontal"} gap={2} style={{cursor: "pointer"}}
                                           onClick={() => handlerFileSelect(file)}>
                                        <img alt={file.name}
                                             src={`https://${file.domain}/${file.path}/${file.file}?class=thumbnail`}
                                             className={"img-folder-m me-2"}
                                             onError={(e) => handlerImageError(e, file._id)}
                                        />
                                        <div
                                            className={`ps-2 ${is_folder_selected ? "folder-name-overflow" : "folder-name"}`}>{file.name}</div>
                                        <div className={"vr ms-auto"}></div>
                                    </Stack>
                                </h5>
                                <Stack direction={`${is_folder_selected ? "horizontal" : "vertical"}`} gap={2}>
                                    <div className="ps-2 mr-2">
                                        <small
                                            className={"folder-time-align-end"}>{moment(file.updatedAt).fromNow()}</small>

                                    </div>
                                    <Stack direction={`${is_folder_selected ? "vertical" : "horizontal"}`} gap={0}
                                           className="ps-2 mr-2">
                                        <button type={"button"} className={"link-primary border-0 text-warning"}>
                                            <BiPencil/>
                                        </button>
                                        {!loadingImages[file._id] && ( // Resim yüklenirken delete butonunu gizle
                                            <button type={"button"} className={"link-primary border-0 text-danger"}
                                                    onClick={(e) => handlerFileDelete(file, e)}>
                                                <BiSolidTrash/>
                                            </button>
                                        )}
                                    </Stack>
                                </Stack>
                            </div>
                        </div>
                    ))}
                </div>
            </Card.Body>
            <Card.Footer>
                <button type={"button"} className={"btn-link border-0"}
                        onClick={() => setExpandFileList(!expandFileList)}>
                    {!expandFileList ? <>
                        <BiDownArrow/> Maximize
                    </> : <>
                        <BiUpArrow/> Minimize
                    </>}
                </button>
            </Card.Footer>
        </Card>
    );
};

export default Folders;
