import React from 'react';
import {Card, Col, Row, Stack} from "react-bootstrap";
/*import Loading from "../_layout/Loading";*/
import "../_layout/Loading.css";
/*import Config from "../../Config"
import {toast} from 'react-toastify';*/
import UrlList from "./UrlList";
import {useDispatch} from "react-redux";
import {setSelectedImage, setShowImage} from "../../store/folderSlice";
import {BiWindowClose} from "react-icons/bi";

const ImageShow = ({image}) => {
    /*const [id, setId] = useState();
    const [, setUrls] = useState({
        view: "",
        direct: "",
        bbcode: "",
        html: ""
    });*/
    /*const folders = useSelector(state => state.filemanager?.folders);  // Store'dan hata mesajını alıyoruz
    const currentFolder = useSelector(state => state.filemanager?.currentFolder);  // Store'dan hata mesajını alıyoruz
    const defaultFolder = useSelector(state => state.filemanager?.defaultFolder);
    const breadcrumb = useSelector(state => state.filemanager?.breadcrumb);
    const user = useSelector(state => state.auth.user);*/
    const dispatch = useDispatch();

    // your function to copy here

    const handlerClose = () => {
        dispatch(setShowImage(false));
        dispatch(setSelectedImage(null));
    }

    return (
        <>
            {image && (
                <Row className={"position-absolute z-3"} style={{maxWidth: "800px"}}>
                    <Col>
                        <Card className={"shadow"}>
                            <Card.Header className={"shadow-sm"}>
                                <h3>
                                <Stack direction={"horizontal"}>
                                    <span>Imqge Preview</span>
                                    <button className={"ms-auto btn-link border-0"} type={"button"} onClick={() => handlerClose()}>
                                        <BiWindowClose />
                                    </button>
                                </Stack>
                                </h3>
                            </Card.Header>
                        <Card.Body>
                                <Row>
                                    <Col className={"text-center"}>
                                        <img src={`https://${image.domain}/${image.path}/${image.file}`} alt="Uploaded"
                                             className={"img-fluid rounded rounded-2"}/>
                                    </Col>
                                    <Col>
                                        <UrlList id={image.slug} image_url={`https://${image.domain}/${image.path}/${image.file}`}/>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            )}
        </>
    );
};

export default ImageShow;
