import {configureStore} from '@reduxjs/toolkit';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Default olarak localStorage kullanılır
import authReducer from './store/authSlice';
import folderReducer from './store/folderSlice';
import styleReducer from './store/styleSlice';
import socketReducer from './store/socketSlice'; // Yeni eklenen socket slice

const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, authReducer);

const store = configureStore({
    reducer: {
        auth: persistedReducer,
        filemanager: folderReducer,
        style: styleReducer,
        socket: socketReducer, // Yeni eklenen socket reducer

    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export const persistor = persistStore(store);

export default store;
