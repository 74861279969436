import {BrowserRouter, Routes, Route} from "react-router-dom";
import Home from "./component/Home";
import './App.css';
import Header from "./component/_layout/Header";
import View from "./component/View";
import {Flip, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Socket from "./component/Socket";
import {useSelector} from "react-redux";
import NonLoginIndex from "./component/Home/nonLoginIndex";

function App() {
    const user = useSelector(state => state.auth?.user);
    return (
        <>
            <Header/>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={user ? <Home/>:<NonLoginIndex />}/>
                    <Route path="/:id" element={<View />} />
                </Routes>
            </BrowserRouter>
            <div className="container-fluid position-fixed bottom-0 bg-light">
            <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 border-top">
                <div className="col-md-4 d-flex align-items-center">
                <a href="https://www.producthunt.com/posts/img-rapid-ink?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-img&#0045;rapid&#0045;ink" className="me-4" target="_blank" rel="noreferrer"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=420339&theme=light" alt="Img&#0032;Rapid&#0032;Ink - Images&#0032;That&#0032;Disappear&#0032;Over&#0032;Time&#0058;&#0032;Share&#0044;&#0032;Let&#0032;Go&#0033; | Product Hunt" style={{width: "250px", height: "54px"}} width="250" height="54" /></a>
                    <span className="mb-3 mb-md-0 text-body-secondary">© 2023 Rpd.ink, Inc</span>
                </div>

                <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
                    <li className="ms-3"><a className="text-body-secondary" href="https://rpd.ink" target="_blank" rel="noreferrer">Link Shortener</a></li>
                </ul>
            </footer>
            </div>
            <ToastContainer
            position={"bottom-right"}
            closeButton={true}
            closeOnClick={true}
            transition={Flip}
            theme={"dark"}
            />
            <Socket />
        </>
    )
}

export default App;
