import {Badge} from "react-bootstrap";
import {useSelector} from "react-redux";
import LoginModal from '../LoginModal';
import { useState} from "react";
import RegisterModal from "../RegisterModal";
import LetteredAvatar from 'react-lettered-avatar';
import UpgradeButton from "../Pricing/UpgradeButton";

const Header = () => {
    const [loginModalShow, setLoginModalShow] = useState(false);
    const [registerModalShow, setRegisterModalShow] = useState(false);

    const user = useSelector(state => state.auth.user);

    const handleLoginOpenModal = () => setLoginModalShow(true);
    const handleLoginCloseModal = () => setLoginModalShow(false);

    const handleRegisterOpenModal = () => setRegisterModalShow(true);
    const handleRegisterCloseModal = () => setRegisterModalShow(false);
    const handleLogout = () => {
        localStorage.removeItem("persist:root");
        document.location.reload();
    }
    /*useEffect(() => {
        console.log("user",user)
    }, [user]);*/
    return <header className="d-flex flex-wrap justify-content-center py-3 mb-4 border-bottom">
        <a href="/" id={"top"}
           className="d-flex align-items-center  mb-md-0 me-md-auto m-sm-0 link-body-emphasis text-decoration-none">
            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
            <img src={"/assets/images/icon.png"} style={{width: "20px"}} className={"mx-2"}
                 alt={"Rapid Free Image Share"}/>
            <span className="fs-4">Image Share</span>
            <small style={{marginLeft: "-40px", marginTop: "25px"}}><Badge bg="warning" text="dark">beta</Badge></small>
        </a>

        <ul className="nav nav-pills">
            <li className="nav-item"><a href="/" className="nav-link mt-1" aria-current="page">Home</a></li>
            <li className="nav-item d-none"><a href="#top" className="nav-link">Pricing</a></li>
            <li className="nav-item d-none"><a href="#top" className="nav-link">FAQs</a></li>
            <li className="nav-item d-none"><a href="#top" className="nav-link">About</a></li>
            {user?.subscription?.is_trial &&
                <li className="nav-item">
                    <UpgradeButton />
                    {/*<a href="#top" className="nav-link mt-1 active">Upgrade</a>*/}
                </li>}
            {user && <li className="nav-item"><a href="/" className="nav-link mt-1"
                                                 aria-current="page">{(user?.total_storage / 1048576).toFixed(2)}MB
                / {user.subscription?.max_storage_size / 1048576}MB</a></li>}
            {user ? (
                <li className="nav-item dropdown">
                    <a href="#profile" className="nav-link dropdown-toggle d-flex align-items-center"
                       id="navbarDropdown" role="button"
                       data-bs-toggle="dropdown" aria-expanded="false">
                        {/*<img src={user.avatarUrl} alt="avatar" className="rounded-circle" width="30" height="30"/>*/}
                        <LetteredAvatar name={user.username} size={32}/>
                        <span className="ms-2">{user.username}</span>
                    </a>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><a className="dropdown-item" href="#profile">Profile</a></li>
                        <li><a className="dropdown-item" href="#settings">Settings</a></li>
                        <li>
                            <hr className="dropdown-divider"/>
                        </li>
                        <li><button className="dropdown-item" onClick={() => { handleLogout() }}>Logout</button></li>
                    </ul>
                </li>
            ) : (
                <>
                    <li className="nav-item"><a href="https://rpd.ink/" target="_blank" rel="noreferrer"
                                                className="nav-link">Link Shortener</a></li>
                    <li className="nav-item">
                        <a href="#login" className="nav-link" onClick={handleLoginOpenModal} type="button">Login</a>
                        <LoginModal show={loginModalShow} handleClose={handleLoginCloseModal}/>
                    </li>
                    <li className="nav-item">
                        <a href="#register" className="nav-link" onClick={handleRegisterOpenModal}
                           type="button">Register</a>
                        <RegisterModal show={registerModalShow} handleClose={handleRegisterCloseModal}/>
                    </li>
                </>
            )}
        </ul>


    </header>;
}

export default Header;
