import React, {useEffect, useState} from "react"
import {Col, Container, Row, Accordion} from "react-bootstrap";
import {useParams} from "react-router-dom";
import axios from "axios";
import "../_layout/Loading.css";
import "./style.css";
import Config from "../../Config"
import Comment from "../Comment/index";
import Comments from "../Comment/Comments";
import UrlList from "../Home/UrlList";
import ImageZoom from "react-image-zooom";

function View() {
    const [image, setImage] = useState("/assets/images/spinner.svg");
    const {id} = useParams();
    const [note, setNote] = useState("");
    const [guestNum, setGuestNum] = useState(0);
    const [ttl, setTtl] = useState(3600);
    const [comments, setComments] = useState({id, ttl, comments: []});
    //const [refreshComments, setRefreshComments] = useState(false);

    useEffect(() => {
        /*
        * Reklam buradan ekleniyor. Bitmedia
        * */
/*

        const loadExternalScript2 = () => {
            const scriptElement = document.createElement('script');
            scriptElement.type = 'text/javascript';
            scriptElement.async = true;
            scriptElement.src = 'https://cdn.bmcdn6.com/js/65634aae74ca5380b8c9447b.js?v=' + new Date().getTime();

            scriptElement.onerror = () => {
                scriptElement.remove();
            };

            document.body.appendChild(scriptElement);
        };
        const loadExternalScript = () => {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = 'https://cdn.bmcdn6.com/js/6562452474ca5380b8c2d4e5.js?v=' + new Date().getTime();

            script.onerror = () => {
                script.remove();
            };

            document.body.appendChild(script);
        };
*/

        //loadExternalScript();
        //loadExternalScript2();

        return () => {
            // Cleanup if needed (optional)
            const existingScript = document.querySelector('script[src="https://cdn.bmcdn6.com/js/6562452474ca5380b8c2d4e5.js"]');
            if (existingScript) {
                existingScript.remove();
            }
        };
    }, []);

    const askView = async () => {
        try {
            //setLoading(true);
            const response = await axios.get(Config.api_base + "/v1/widget/image/" + id, {
                id: id
            }, {
                timeout: 120000 // 2 dakika (milisaniye cinsinden)
            });
            let src = "";
            if (response.data.status === 404) {
                setImage("/assets/images/404.png");
            } else {
                const image_json = response.data.image; // Buradaki base64Response, Worker'dan dönen JSON yanıtıdır
                src = `https://${image_json.domain}/${image_json.path}/${image_json.file}`;
                /*console.log(src)*/
                setImage(src);
                //console.log("image_json",image_json);
                setNote(image_json.description);
                setTtl(Number(image_json.timeout) * 60);
                //setLoading(false);
                setComments({
                    id: id,
                    ttl: response.data.image.timeout,
                    comments: response.data.comments
                });

                return response.data;
            }

        } catch (error) {
            //toast.error("Image removed");
            console.error('Hata:', error);
        }
    }
/*    const getComments = async () => {
        try {
            //setLoading(true);
            const response = await axios.get(Config.api_base + "/api/image/comments/" + id);

            if (response.data.status === 404) {
                console.log(404)
            } else {
                //console.log("saved",response.data);
                if (comments.comments?.length !== response.data?.length) {
                    setComments({
                        id: id,
                        ttl: ttl,
                        comments: response.data
                    });
                }
                return response.data;
            }


        } catch (error) {
            //toast.error("Image removed");
            console.error('Hata:', error);
        }
    }*/

    const guestNumberGen = () => {
        const guestNumStorage = Number(localStorage.getItem("guestNum"));
        if (guestNumStorage) {
            setGuestNum(guestNumStorage);
        } else {
            const num = Math.abs(Math.floor(Math.random() * (10000 - 99999 + 1)) + 10000);
            localStorage.setItem("guestNum", num.toString());
            setGuestNum(num);
        }
    }

    useEffect(() => {
        guestNumberGen();
        askView().then((res) => {
            //console.log(res);
            //console.log(res);
        });
        // eslint-disable-next-line
    }, []);

/*    useEffect(() => {
        let checkComments = setInterval(() => {
            getComments().then((res) => {
                if (comments.comments?.length !== res?.length) {
                    setComments({
                        id: id,
                        ttl: ttl,
                        comments: res
                    });
                }

                clearInterval(checkComments);
                setRefreshComments(!refreshComments);
            });
        }, 10 * 1000);
// eslint-disable-next-line
    }, [refreshComments]);*/

    return (
        <Container fluid={true} className={"mx-auto my-auto"}>
            <Row>
                <Col className={"text-center"}>
                    {/*Reklam Gösterimi bitmedia*/}
                    <ins className="6562452474ca5380b8c2d4e5"
                         style={{display: "inline-block", width: "1px", height: "1px"}}></ins>
                </Col>
            </Row>
            <Row>
                <Col md={8} className={"p-0 pe-4 text-center"}>
                    {(image === "/assets/images/spinner.svg") ?
                        <p><img src={image} style={{width: "64px"}} alt={"Loading"}/><br/>Loading...</p> :
                        <ImageZoom src={image} className={"img-fluid"} alt="Uploaded image" zoom="200"/>}

                    <div>
                        <ins className="65634aae74ca5380b8c9447b"
                             style={{display: "inline-block", width: "1px", height: "1px"}}></ins>
                    </div>


                </Col>
                <Col className={"p-0"}>
                    <Accordion className={"mb-2"}>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Copy Address</Accordion.Header>
                            <Accordion.Body className={"p-2"}>
                                <UrlList id={id} image_url={image} img_link={false}/>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    {<Comment data={{type: "uploader", note, guestNum, ttl}}/>}

                    <Comments data={comments}/>

                </Col>
            </Row>

        </Container>
    )
}

export default View;
